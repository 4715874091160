import { options } from 'chartkick';
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'buttonChartByHours',
    'buttonChartByDaysWeek',
    'buttonChartByMonths',
    'buttonChartByWeeks',
    'buttonChartByDays',
    'chartHours',
    'chartDays',
    'chartDaysWeek',
    'chartMonths',
    'chartWeeks',
  ];

  connect() {
    this.toggleChartByHours();
    this.toggleChartByMonths();
  }

  toggleChartByDaysWeek(e) {
    this.buttonChartByHoursTargets[0].classList.remove(
      'clients-button--chart-active'
    );
    this.buttonChartByDaysWeekTargets[0].classList.add(
      'clients-button--chart-active'
    );

    if (Chart.getChart('chart_by_hours'))
      Chart.getChart('chart_by_hours').destroy();

    this.chartHoursTargets[0].style.display = 'none';
    this.chartDaysWeekTargets[0].style.display = 'block';

    const chart_data_days = Object.keys(
      gon.charts_data['leads_by_days_of_the_week']
    );
    const chart_data_count = Object.values(
      gon.charts_data['leads_by_days_of_the_week']
    );
    const ctx = document.getElementById('chart_by_days_week')?.getContext('2d');
    if (ctx === undefined) return;

    this.new_chart(ctx, chart_data_days, chart_data_count);
  }

  toggleChartByHours() {
    this.buttonChartByHoursTargets[0].classList.add(
      'clients-button--chart-active'
    );
    this.buttonChartByDaysWeekTargets[0].classList.remove(
      'clients-button--chart-active'
    );

    if (Chart.getChart('chart_by_days_week'))
      Chart.getChart('chart_by_days_week').destroy();

    this.chartDaysWeekTargets[0].style.display = 'none';
    this.chartHoursTargets[0].style.display = 'block';

    if (JSON.stringify(gon.charts_data['leads_by_hours_of_the_day']) === '{}') {
      console.log('No data');
    } else {
      const chart_data_hours = Object.keys(
        gon.charts_data['leads_by_hours_of_the_day']
      );
      const chart_data_count = Object.values(
        gon.charts_data['leads_by_hours_of_the_day']
      );
      const ctx = document.getElementById('chart_by_hours')?.getContext('2d');
      if (ctx === undefined) return;

      this.new_chart(ctx, chart_data_hours, chart_data_count);
    }
  }

  toggleChartByMonths() {
    this.buttonChartByMonthsTargets[0].classList.add(
      'clients-button--chart-active'
    );
    this.buttonChartByWeeksTargets[0].classList.remove(
      'clients-button--chart-active'
    );
    this.buttonChartByDaysTargets[0].classList.remove(
      'clients-button--chart-active'
    );

    if (Chart.getChart('chart_by_week'))
      Chart.getChart('chart_by_week').destroy();
    if (Chart.getChart('chart_by_day'))
      Chart.getChart('chart_by_day').destroy();

    this.chartWeeksTargets[0].style.display = 'none';
    this.chartDaysTargets[0].style.display = 'none';
    this.chartMonthsTargets[0].style.display = 'block';

    if (JSON.stringify(gon.charts_data['leads_by_month']) === '{}') {
      console.log('No data');
    } else {
      const chart_data_month = Object.keys(gon.charts_data['leads_by_month']);
      const chart_data_count = Object.values(gon.charts_data['leads_by_month']);
      const ctx = document.getElementById('chart_by_month')?.getContext('2d');
      if (ctx === undefined) return;

      this.new_chart(ctx, chart_data_month, chart_data_count);
    }
  }

  toggleChartByWeeks() {
    this.buttonChartByWeeksTargets[0].classList.add(
      'clients-button--chart-active'
    );
    this.buttonChartByMonthsTargets[0].classList.remove(
      'clients-button--chart-active'
    );
    this.buttonChartByDaysTargets[0].classList.remove(
      'clients-button--chart-active'
    );

    if (Chart.getChart('chart_by_month'))
      Chart.getChart('chart_by_month').destroy();
    if (Chart.getChart('chart_by_day'))
      Chart.getChart('chart_by_day').destroy();

    this.chartDaysTargets[0].style.display = 'none';
    this.chartMonthsTargets[0].style.display = 'none';
    this.chartWeeksTargets[0].style.display = 'block';

    const chart_data_week = Object.keys(gon.charts_data['leads_by_week']);
    const chart_data_count = Object.values(gon.charts_data['leads_by_week']);
    const ctx = document.getElementById('chart_by_week')?.getContext('2d');
    if (ctx === undefined) return;

    this.new_chart(ctx, chart_data_week, chart_data_count);
  }

  toggleChartByDays() {
    this.buttonChartByDaysTargets[0].classList.add(
      'clients-button--chart-active'
    );
    this.buttonChartByMonthsTargets[0].classList.remove(
      'clients-button--chart-active'
    );
    this.buttonChartByWeeksTargets[0].classList.remove(
      'clients-button--chart-active'
    );

    if (Chart.getChart('chart_by_week'))
      Chart.getChart('chart_by_week').destroy();
    if (Chart.getChart('chart_by_month'))
      Chart.getChart('chart_by_month').destroy();

    this.chartWeeksTargets[0].style.display = 'none';
    this.chartMonthsTargets[0].style.display = 'none';
    this.chartDaysTargets[0].style.display = 'block';

    const chart_data_day = Object.keys(gon.charts_data['leads_by_day']);
    const chart_data_count = Object.values(gon.charts_data['leads_by_day']);
    const ctx = document.getElementById('chart_by_day')?.getContext('2d');
    if (ctx === undefined) return;

    this.new_chart(ctx, chart_data_day, chart_data_count);
  }

  new_chart(ctx, chart_data, chart_data_count) {
    return new Chart(ctx, {
      type: 'bar',
      height: 300,
      data: {
        labels: chart_data,
        datasets: [
          {
            data: chart_data_count,
            backgroundColor: ['#B1C5F5'],
          },
        ],
      },
      options: this.options_chart(),
    });
  }

  options_chart() {
    return {
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        padding: {
          top: 50,
          bottom: 30,
          left: 10,
          right: 10,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
      },
    };
  }
}

// plugins: {
//   legend: {
//     position: "bottom",
//     labels: {
//       generateLabels: (chart) => {
//         return chart.data.labels.map(
//           (label, index) => ({
//             text: label,
//             fillStyle: chart.data.datasets[0].backgroundColor[index],
//           })
//         )
//       }
//     }
//   }
// }
