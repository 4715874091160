import Axios from 'axios';
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['file_field', 'submitButton', 'nameField', 'loader'];

  upload(event) {
    event.target.form.submit.click();
  }

  timeoutSubmit = null;
  timeoutLoader = null;

  submitDocName(event) {
    clearTimeout(this.timeoutSubmit);
    clearTimeout(this.timeoutLoader);

    if (!this.loaderTarget.classList.contains('mission-documents__loader')) {
      this.loaderTarget.classList.remove('mission-documents__valid');
      this.loaderTarget.classList.add('mission-documents__loader');
    }

    this.timeoutLoader = setTimeout(() => {
      this.loaderTarget.classList.remove('mission-documents__loader');
      this.loaderTarget.classList.add('mission-documents__valid');
    }, 2000);

    this.timeoutSubmit = setTimeout(() => {
      Axios.patch(
        `/missions/${window.location.pathname.split('/')[2]}/update_document`,
        {
          name: event.target.value,
          mission_document_id: event.target.dataset.documentId,
          authenticity_token: document
            .querySelector("meta[name='csrf-token']")
            .getAttribute('content'),
        }
      )
        .catch((error) => {
          console.log('erreur', error);
        })
        .then((response) => {
          location.reload();
        });
    }, 3000);
  }

  approveDocument(event) {
    if (event.target.checked) {
      setTimeout(() => {
        this.submitButtonTarget.click();
      }, 1000);
    }
  }
}
