import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['ibanInput', 'ibanError', 'ibanLabel'];

  connect() {
    console.log('IbanValidationController connected');
  }

  submit(event) {
    const ibanValue = this.ibanInputTarget.value;
    const ibanErrorElement = this.ibanErrorTarget;

    if (!this.isValidIBAN(ibanValue)) {
      console.log('IBAN invalide : ' + ibanValue);

      this.displayInvalidIBANError();
      ibanErrorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      event.preventDefault();
    } else {
      this.removeInvalidIBANError();
      console.log('IBAN valide : ' + ibanValue);
    }
  }

  isValidIBAN(iban) {
    const ibanRegex = /^[A-Z]{2}[0-9A-Z\s]{4,}$/;
    return iban.length == 0 || ibanRegex.test(iban);
  }

  displayInvalidIBANError() {
    this.ibanLabelTarget.classList.add('label__error');
    this.ibanErrorTarget.innerHTML = 'IBAN invalide';
  }

  removeInvalidIBANError() {
    this.ibanLabelTarget.classList.remove('label__error');
    this.ibanErrorTarget.innerHTML = '';
  }
}
